import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { title: '首頁' },
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/person_desp',
    name: 'person_desp',
    meta: { title: '藏書閣主' },
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/website_desp',
    name: 'website_desp',
    meta: { title: '網站說明' },
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/biography',
    name: 'biography',
    meta: { title: '百人小傳' },
    component: () => import('../views/Biography.vue'),
  },
  {
    path: '/treasure',
    name: 'treasure',
    meta: { title: '觀古珍藏' },
    component: () => import('../views/Treasure.vue'),
  },
  {
    path: '/visual_display',
    name: 'visual_display',
    meta: { title: '視覺展示' },
    component: () => import('../views/VisualDisplay.vue'),
  },
  {
    path: '/dbsearch',
    name: 'dbsearch',
    meta: { title: '資料庫檢索' },
    component: () => import('../views/DBSearch.vue'),
  },
  {
    path: '/topic_research',
    name: 'topic_research',
    meta: { title: '主題研究' },
    component: () => import('../views/TopicResearch.vue'),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  Vue.$gtag.pageview(to.path);
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
