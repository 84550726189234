import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import i18n from './common/plugins/vue-i18n';

Vue.use(VueAxios, axios);
Vue.use(VueGtag, {
  config: { id: 'G-KLDJHKLCZE' },
}, router);
Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
